<template lang="html">
  <div class="list-wrapper">
    <v-card shaped class="list-content" >

      <v-card-title  class='handle'>
        <InlineTextEditor :text="container.name" @update="saveName" />
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="boardAdmin" icon @click='toggleView' >
              <v-icon v-if='!container.hidden' v-bind="attrs" v-on="on">mdi-eye</v-icon>
              <v-icon v-if='container.hidden' v-bind="attrs" v-on="on">mdi-eye-off</v-icon>
            </v-btn>
          </template>
          <span v-if='!container.hidden'>Seen by players (click to hide)</span><span v-if='container.hidden'>Hidden from players (click to show)</span>

        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col sm="3">
            <v-badge bordered color="grey darken-2" overlap top left content="cp" style="width:100%;text-align: right;" offset-x="20" offset-y="8">
              <v-chip color="grey darken-3" text-color="white" class="d-flex" style="border-radius: 4px 20px;">
                <InlineNumberEditor :value="container.cp" @update="saveCP" style="width:100%;"></InlineNumberEditor>
              </v-chip>
            </v-badge>
          </v-col>
          <v-col sm="3">
            <v-badge bordered color="grey darken-2" overlap top left content="sp" style="width:100%;text-align: right;" offset-x="20" offset-y="8">
              <v-chip color="grey darken-3" text-color="white" class="d-flex" style="border-radius: 4px 20px;">
              <InlineNumberEditor :value="container.sp" @update="saveSP" style="width:100%;"></InlineNumberEditor>
              </v-chip>
            </v-badge>
          </v-col>
          <v-col sm="3">
            <v-badge bordered color="grey darken-2" overlap top left content="gp" style="width:100%;text-align: right;" offset-x="20" offset-y="8">
              <v-chip color="grey darken-3" text-color="white" class="d-flex" style="border-radius: 4px 20px;">
              <InlineNumberEditor :value="container.gp" @update="saveGP" style="width:100%;"></InlineNumberEditor>
              </v-chip>
            </v-badge>
          </v-col>
          <v-col sm="3">
            <v-badge bordered color="grey darken-2" overlap top left content="pp" style="width:100%;text-align: right;" offset-x="20" offset-y="8">
              <v-chip color="grey darken-3" text-color="white" class="d-flex" style="border-radius: 4px 20px;">
              <InlineNumberEditor :value="container.pp" @update="savePP" style="width:100%;"></InlineNumberEditor>
              </v-chip>
            </v-badge>
          </v-col>
        </v-row>
      </v-card-text>

      <draggable class="list-cards" :list="cards" group="cards" @change="dragUpdate" style="min-height:50px;">
        <CardComponent v-for="card in cards" :key="card.id" :card="card" @view-card="viewCard"></CardComponent>
      </draggable>

      <v-card-actions>
        <v-btn @click="createCard()">New item</v-btn>
        <v-btn v-if="cards.length < 1" @click="deleteContainer()" :loading="processing">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import CardComponent from './CardComponent'
import InlineTextEditor from "./InlineTextEditor"
import InlineNumberEditor from '@/components/InlineNumberEditor'
import draggable from "vuedraggable";
import { mapState } from "vuex";
export default {
  components: {
    draggable,
    CardComponent,
    InlineTextEditor,
    InlineNumberEditor
  },
  props: { container: Object, boardAdmin: Boolean },
  data() {
    return {
      unsubscribeFunction: null,
      cards: [],
      processing: false
    };
  },
  computed: {
    ...mapState(["db"])
  },
  methods: {
    createCard() {
      let max_pos = Math.max.apply(Math, this.cards.map(function(c) { return c.position ? c.position : 0 }));
      let self = this
      this.db.cardsCollection.add({
        name: '',
        container_id: this.container.id,
        position: ((max_pos > 0) ? max_pos : 0) + 4086
      }).then(function(cardRef) {
        self.$emit('view-card', cardRef);
        self.$store.commit("addUndo", {
          action: 'delete', entity: `cards/${cardRef.id}`
        });

      })

    },
    viewCard(card) {
      this.$emit('view-card', card)
    },
    async deleteContainer() {
      this.processing = true
      this.$store.commit("addUndo", {
        action: 'create', entity: `containers/${this.container.id}`, values: this.container
      });
      await this.db.containersCollection.doc(this.container.id).delete()
    },
    async toggleView() {
      await this.db.containersCollection.doc(this.container.id).update({
        hidden: !this.container.hidden
      })
    },
    dragUpdate(evt) {

      let changes = {}
      let event = null

      if (evt.added != undefined) {
        changes.container_id = this.container.id
        event = evt.added.element
      }

      if (evt.moved != undefined) {
        event = evt.moved.element
      }

      if (event != null) {
        let idx = this.cards.findIndex(e => e.id === event.id)

        if (this.cards.length == 1) { changes.position = 4086 }
        else if (idx == 0) { changes.position = this.cards[1].position/2 }
        else if (idx == (this.cards.length-1) ) { changes.position = this.cards[this.cards.length-2].position+4086 }
        else { changes.position = (this.cards[idx-1].position + this.cards[idx+1].position)/2 }

        this.$store.commit("addUndo", {
          action: 'update', entity: `cards/${event.id}`, values: { container_id: event.container_id, position: event.position }
        });
        this.db.cardsCollection.doc(event.id).update(changes);
      }
    },
    saveName(newName) {
      this.$store.commit("addUndo", { action: 'update', entity: `containers/${this.container.id}`, values: { name: this.container.name } });
      this.db.containersCollection.doc(this.container.id).update({name: newName})
    },
    saveCP(value) {
      this.$store.commit("addUndo", { action: 'update', entity: `containers/${this.container.id}`, values: { cp: this.container.cp } });
      this.db.containersCollection.doc(this.container.id).update({cp: value})
    },
    saveSP(value) {
      this.$store.commit("addUndo", { action: 'update', entity: `containers/${this.container.id}`, values: { sp: this.container.sp } });
      this.db.containersCollection.doc(this.container.id).update({sp: value})
    },
    saveGP(value) {
      this.$store.commit("addUndo", { action: 'update', entity: `containers/${this.container.id}`, values: { gp: this.container.gp } });
      this.db.containersCollection.doc(this.container.id).update({gp: value})
    },
    savePP(value) {
      this.$store.commit("addUndo", { action: 'update', entity: `containers/${this.container.id}`, values: { pp: this.container.pp } });
      this.db.containersCollection.doc(this.container.id).update({pp: value})
    },
  },
  mounted() {
    this.db.cardsCollection.where("container_id", "==", this.container.id).orderBy("position").onSnapshot(snapshot => {
      let cardsArray = [];

      snapshot.forEach(doc => {
        let card = doc.data();
        card.id = doc.id;

        cardsArray.push(card);
      });

      this.cards = cardsArray;
    });
  },
  beforeDestroy() {
    if (this.unsubscribeFunction != null) this.unsubscribeFunction;
  }
}
</script>

<style lang="css">
.list-wrapper {
  width: 300px;
  margin: 0 4px;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}
.list-content {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
}
.list-cards {
  flex: 1 1 auto;
  overflow-y: auto;
  margin: 0 4px;
  padding: 4px 4px;
  min-height: 0;
}
.v-chip .v-chip__content {
    width: 100%;
}
</style>
