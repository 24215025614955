<template lang="html">
  <div style="overflow-wrap:normal;">
    <span v-if="!editing" @click="startEdit" style="cursor:pointer;word-break: normal;">{{text || defaultText}}</span>
    <v-text-field type="text"
    v-model.trim="interim_value"
    ref="inputbox"
    v-show="editing"
    @blur="saveEdit()"
    @keydown.escape="cancelEdit($event)"
    @keyup.enter="saveEdit()"
    onfocus="this.select();"
    class="py-0"
    />
  </div>
</template>

<script>
export default {
  props: { text: String, defaultText: String },
  data() {
    return {
      interim_value: '',
      editing: false
    }
  },
  methods: {
    startEdit() {
      this.interim_value = this.text
      this.editing = true
      this.$nextTick(() => {
        this.$refs.inputbox.focus()
      })
    },
    saveEdit() {
      if (!this.editing) return
      if (this.interim_value == '') { this.cancelEdit(); return }
      this.$emit('update', this.interim_value)
      this.editing = false
    },
    cancelEdit(event) {
      if (!this.editing) return
      if (event) event.stopPropagation()
      this.editing = false
    }
  }
}
</script>

<style lang="css" scoped>
</style>
