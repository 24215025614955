<template lang="html">
  <div>
    <blockquote class="font-italic" v-if="!editing" @click="startEdit" style="white-space: pre-wrap; cursor:pointer">{{ text || defaultText}}</blockquote>
    <v-textarea v-if="editing"
    filled
    v-model.trim="interim_value"
    ref="inputbox"
    v-show="editing"
    @blur="saveEdit()"
    @keydown.escape="cancelEdit($event)"
    @keyup.enter.exact="saveEdit()"
    class="py-0"
    />
  </div>
</template>

<script>
export default {
  props: { text: String, defaultText: String },
  data() {
    return {
      interim_value: '',
      editing: false
    }
  },
  methods: {
    startEdit() {
      this.interim_value = this.text
      this.editing = true
      this.$nextTick(() => this.$refs.inputbox.focus())
    },
    saveEdit() {
      if (!this.editing) return
      this.$emit('update', this.interim_value)
      this.editing = false
    },
    cancelEdit(event) {
      if (!this.editing) return
      event.stopPropagation()
      this.editing = false
    }
  }
}
</script>

<style lang="css" scoped>
</style>
