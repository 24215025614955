<template lang="html">
  <v-dialog v-model="cardDialogOpen" width="450" transition="dialog-bottom-transition" scrollable>
    <v-card shaped outlined class="grey darken-3 pa-3" @drop="onDrop" @dragenter.prevent @dragover.prevent :loading="loading">
      <template slot="progress">
        <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
        ></v-progress-linear>
      </template>
      <v-img v-if="card.img_url"
      contain
      max-height="350"
      :src="card.img_url"
      ></v-img>
      <v-card-title class="headline">
        <v-row>
          <v-col sm="9">
            <CardTitleSelector :text="card.name" defaultText="Unnamed Item" @update="saveName" @applyTemplate="applyTemplate" style="overflow-x:hidden;" ref="nameComponent" />
          </v-col>
          <v-col sm="3" class="text-right" :style="{color: computedMultiplesColor}">
            <InlineNumberEditor :value="computedMultiples" @update="saveMultiples"><span slot="prefix">x</span></InlineNumberEditor>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle class="mt-1 d-flex">
        <v-row>
          <v-col sm="6">
            <InlineTextEditor :text="card.subtitle" defaultText="Standard item" @update="saveSubtitle" class="mr-auto" />
          </v-col>
          <v-col sm="3" class="text-right">
            <InlineNumberEditor :value="card.worth" @update="saveWorth"><span slot="postfix">gp</span></InlineNumberEditor>
          </v-col>
          <v-col sm="3" class="text-right">
            <InlineNumberEditor :value="card.weight" @update="saveWeight"><span slot="postfix">lb</span></InlineNumberEditor>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <div v-if="card.max_charges">
          <v-slider
          :value="card.charges"
          step="1"
          ticks="always"
          :max="card.max_charges"
          tick-size="15"
          label="Charges"
          hide-details
          @end="saveCharges"
          ></v-slider>
        </div>
        <v-checkbox class="mt-0" hide-details v-if="card.is_attunement" v-model="card.is_attuned" @change="saveAttuned" label="Attuned"></v-checkbox>

        <InlineTextareaEditor class="mt-5" :text="card.description" defaultText="No description" @update="saveDescription" />


        <div class="" v-show="settingsPanelOpen">
          <v-divider class="my-5"></v-divider>

          <v-checkbox class="mt-0" dense hide-details v-model="card.is_magical" @change="saveMagical" label="Magical" />
          <v-checkbox class="mt-0" dense hide-details v-model="card.is_attunement" @change="saveAttunement" label="Attunement" />
          <InlineNumberEditor :value="card.max_charges" @update="saveMaxCharges" class=" ml-1 mt-2"><span slot="postfix">maximum charges</span></InlineNumberEditor>
          <v-btn v-if="allowCreateTemplate" @click="addCardTemplate()">Add as template item</v-btn>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn :loading="processing" @click="deleteCard">Delete</v-btn>
        <v-btn :loading="processing" @click="sellCard">Sell {{computedMultiples > 1 ? 'All' : ''}}</v-btn>
        <v-spacer/>
        <v-btn color="secondary" @click="settingsPanelOpen = !settingsPanelOpen">{{settingsPanelOpen ? 'Close ' : ''}}Settings</v-btn>
        <v-btn color="secondary" @click="cardDialogOpen = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import InlineTextEditor from "./InlineTextEditor"
import InlineTextareaEditor from "./InlineTextareaEditor"
import InlineNumberEditor from "./InlineNumberEditor"
import CardTitleSelector from "@/components/CardTitleSelector";
export default {
  components: {
    CardTitleSelector,
    InlineTextEditor,
    InlineTextareaEditor,
    InlineNumberEditor
  },
  data() {
    return {
      card_id: null,
      card: {},
      cardDialogOpen: false,
      processing: false,
      unsubscribeFunction: null,
      settingsPanelOpen: false,
      loading: false
    }
  },
  computed: {
    ...mapState(["db", "cardTemplates"]),
    computedMultiples() {
      return this.card.multiples ?? 1
    },
    computedMultiplesColor() {
      return (this.computedMultiples == 1 ? 'grey' : 'white')
    },
    allowCreateTemplate() {
      if (!this.card.name) return false
      return !(this.cardTemplates.some( c => c.name && c.name.toLowerCase() === this.card.name.toLowerCase()))
    }
  },
  methods: {
    setCard(card) {
      this.card_id = card.id
      if (this.unsubscribeFunction != null) this.unsubscribeFunction
      this.unsubscribeFunction = this.db.cardsCollection.doc(card.id).onSnapshot(snapshot => {
        if (snapshot.exists) {
          this.card = snapshot.data()
          this.cardDialogOpen = true
          if (!card.name) this.$nextTick(() => {this.$nextTick(() => this.$refs.nameComponent.startEdit())})
        } else {
          this.card = {}
          this.cardDialogOpen = false
        }
      })

      this.processing = false
      this.settingsPanelOpen = false


    },
    saveName(text) {
      this.$store.commit("addUndo", { action: 'update', entity: `cards/${this.card_id}`, values: { name: this.card.name ?? '' } });
      this.db.cardsCollection.doc(this.card_id).update({name: text ?? ''})
    },
    applyTemplate(cardTemplate) {
      this.$store.commit("addUndo", { action: 'update', entity: `cards/${this.card_id}`, values: this.card });
      this.db.cardsCollection.doc(this.card_id).update(cardTemplate)
    },
    addCardTemplate() {
      let cardCopy = JSON.parse(JSON.stringify(this.card))
      delete cardCopy.id
      delete cardCopy.container_id
      delete cardCopy.multiples
      delete cardCopy.is_attuned
      delete cardCopy.position
      if (cardCopy.max_charges) cardCopy.charges = cardCopy.max_charges
      this.db.cardTemplatesCollection.add(cardCopy)
    },
    saveSubtitle(text) {
      this.$store.commit("addUndo", { action: 'update', entity: `cards/${this.card_id}`, values: { subtitle: this.card.subtitle ?? '' } });
      this.db.cardsCollection.doc(this.card_id).update({subtitle: text ?? ''})
    },
    saveDescription(text) {
      this.$store.commit("addUndo", { action: 'update', entity: `cards/${this.card_id}`, values: { description: this.card.description ?? '' } });
      this.db.cardsCollection.doc(this.card_id).update({description: text ?? ''})
    },
    saveMagical() {
      this.$store.commit("addUndo", { action: 'update', entity: `cards/${this.card_id}`, values: { is_magical: !this.card.is_magical ?? false } });
      this.db.cardsCollection.doc(this.card_id).update({is_magical: this.card.is_magical ?? false})
    },
    saveAttuned() {
      this.$store.commit("addUndo", { action: 'update', entity: `cards/${this.card_id}`, values: { is_attuned: !this.card.is_attuned ?? false } });
      this.db.cardsCollection.doc(this.card_id).update({is_attuned: this.card.is_attuned ?? false})
    },
    saveAttunement() {
      this.$store.commit("addUndo", { action: 'update', entity: `cards/${this.card_id}`, values: { is_attunement: !this.card.is_attunement ?? false } });
      this.db.cardsCollection.doc(this.card_id).update({is_attunement: this.card.is_attunement ?? false})
    },
    saveWorth(value) {
      this.$store.commit("addUndo", { action: 'update', entity: `cards/${this.card_id}`, values: { worth: this.card.worth ?? 0 } });
      this.db.cardsCollection.doc(this.card_id).update({worth: value ?? 0})
    },
    saveWeight(value) {
      this.$store.commit("addUndo", { action: 'update', entity: `cards/${this.card_id}`, values: { weight: this.card.weight ?? 0 } });
      this.db.cardsCollection.doc(this.card_id).update({weight: value ?? 0})
    },
    saveMultiples(value) {
      this.$store.commit("addUndo", { action: 'update', entity: `cards/${this.card_id}`, values: { multiples: this.card.multiples ?? 1 } });
      this.db.cardsCollection.doc(this.card_id).update({multiples: value ?? 1})
    },
    saveMaxCharges(value) {
      this.$store.commit("addUndo", { action: 'update', entity: `cards/${this.card_id}`, values: { max_charges: this.card.max_charges ?? 0 } });
      this.db.cardsCollection.doc(this.card_id).update({max_charges: value ?? 0})
    },
    saveCharges(value) {
      this.$store.commit("addUndo", { action: 'update', entity: `cards/${this.card_id}`, values: { charges: this.card.charges ?? 0 } });
      this.db.cardsCollection.doc(this.card_id).update({charges: value ?? 0})
    },
    async deleteCard() {
      this.processing = true
      //if (this.card.img_loc) this.db.storage.ref(this.card.img_loc).delete()
      this.$store.commit("addUndo", {
        action: 'create', entity: `cards/${this.card_id}`, values: this.card
      });
      await this.db.cardsCollection.doc(this.card_id).delete()
      this.unsubscribeFunction()
      this.card_id = null
      this.cardDialogOpen = false
      this.processing = false
    },
    async sellCard() {
      this.processing = true
      this.$store.commit("addUndo", {
        action: 'unsell', entity: `cards/${this.card_id}`, values: this.card
      });
      await this.db.containersCollection.doc(this.card.container_id).update({
        gp: this.db.fv.increment(this.card.worth * this.computedMultiples)
      })
      await this.db.cardsCollection.doc(this.card_id).delete()
      this.unsubscribeFunction()
      this.card_id = null
      this.cardDialogOpen = false
      this.processing = false
    },
    onDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      let self = this
      let file = e.dataTransfer.files[0]
      self.loading = true
      if (this.card.img_loc) {
        this.db.storage.ref(this.card.img_loc).delete().then(() => {
          self.uploadImage(file)
        })
      } else {
        self.uploadImage(file)
      }
    },
    uploadImage(file) {
      let self = this
      let ext = file.name.split('.').pop()
      var storageRef = this.db.storage.ref('card_imgs/'+this.card_id+'.'+ext)
      var task = storageRef.put(file)
      task.on('state_changed', function progress() {
        //var percentage = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
        //uploader.value = percentage;
      }, function error() {
        self.loading = false

      },function complete() {
        storageRef.getDownloadURL().then(function(url) {
          self.db.cardsCollection.doc(self.card_id).update({
            img_url: url,
            img_loc: 'card_imgs/'+self.card_id+'.'+ext
          })
          self.loading = false
        })
      });
    }
  },
  beforeDestroy() {
    if (this.unsubscribeFunction != null) this.unsubscribeFunction
  }
}
</script>

<style lang="css" scoped>
</style>
