<template lang="html">
  <div>
    <div v-if="!editing" @click="startEdit" style="cursor:pointer;">
      <slot name="prefix"></slot>
      {{formattedValue}}
      <slot name="postfix"></slot>
    </div>
    <v-text-field v-if="editing" type="text"
    dense
    v-model.trim="interim_value"
    ref="inputbox"
    v-show="editing"
    @blur="saveEdit()"
    @keydown.escape="cancelEdit($event)"
    @keyup.enter="saveEdit()"
    onfocus="this.select();"
    class="py-0"
    />

  </div>
</template>

<script>
export default {
  props: { value: Number },
  data() {
    return {
      interim_value: '0',
      editing: false
    }
  },
  computed: {
    formattedValue() {
      return Intl.NumberFormat().format(this.value || 0)
    }
  },
  methods: {
    startEdit() {
      this.interim_value = (this.value || 0).toString()
      this.editing = true
      this.$nextTick(() => this.$refs.inputbox.focus())
    },
    saveEdit() {
      if (!this.editing) return
      if (this.interim_value === '') { this.cancelEdit(); return }
      if (['+','-','*','/'].includes(this.interim_value[0])) this.interim_value = (this.value || 0).toString() + this.interim_value
      let num
      try { num = this.$math.evaluate(this.interim_value).toNumber() }
      catch { this.cancelEdit(); return }
      this.$emit('update', num)
      this.editing = false
    },
    cancelEdit(event) {
      if (!this.editing) return
      if (event != undefined) { event.stopPropagation() }
      this.editing = false
    }
  }
}
</script>

<style lang="css" scoped>
</style>
