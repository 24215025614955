<template lang="html">
  <div style="overflow-wrap:normal;">
    <span v-if="!editing" @click="startEdit" style="cursor:pointer;word-break: normal;">{{text || defaultText}}</span>
    <div v-show="editing">

      <v-text-field
          v-model="interim_value"
          ref="inputbox"

          @blur="saveEdit()"
          @keydown.escape="cancelEdit($event)"
          @keyup.enter="saveEdit()"
          onfocus="this.select();"
          class="py-0"
      >
      </v-text-field>

      <v-list class="below_menu" v-show="filteredItems.length">
        <v-list-item
            v-for="(item, index) in filteredItems"
            :key="index"
            v-on:mousedown="applyTemplate(item)"
            @click="applyTemplate(item)"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>

    </div>


  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: { text: String, defaultText: String },
  data() {
    return {
      interim_value: '',
      editing: false,
      selectedTemplate: null
    }
  },
  computed: {
    ...mapState(["cardTemplates"]),
    filteredItems() {
      const search = this.interim_value.toLowerCase()
      return this.cardTemplates.filter( item => {
        const name = item.name.toLowerCase()
        return name.indexOf(search) >= 0
      })
    }
  },
  methods: {
    startEdit() {
      this.editing = true
      this.selectedTemplate = null
      this.interim_value = this.text
      this.$nextTick(() => {
        this.$refs.inputbox.focus()
      })
    },
    saveEdit() {
      if (!this.editing) return
      if (this.interim_value === "" || this.interim_value == null) { this.cancelEdit(); return }
      this.$emit('update', this.interim_value)
      this.editing = false
      this.$refs.inputbox.blur()
      this.selectedTemplate = null
    },
    applyTemplate(cardTemplate) {
      console.log('here')
      this.$emit('applyTemplate', cardTemplate)

      this.editing = false
      this.$refs.inputbox.blur()
      this.selectedTemplate = null
    },
    cancelEdit(event) {
      if (!this.editing) return
      if (event) event.stopPropagation()

      this.editing = false
      this.$refs.inputbox.blur()
      this.selectedTemplate = null
    },
  }
}
</script>

<style lang="css" scoped>
.below_menu {
  position: absolute;
  max-height: 7em;
  width: 12em;
  overflow-y: auto;
  z-index: 1000;
}
</style>
