<template lang="html">
  <div id="participantsModal" class="ml-2">
    <v-btn dark @click="showParticipants">
      Participants
    </v-btn>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey darken-3">
          Participants
        </v-card-title>

        <v-card-text class="mt-3">
          <v-list-item v-for="participant in participants" :key="participant.id" >
            <v-list-item-content>
              <v-list-item-title>{{participant.name}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="isAdmin && participant.id != db.auth.currentUser.uid">
              <v-btn small v-if="!participant.admin" @click="removePlayer(participant.id)">Remove from game</v-btn>
            </v-list-item-action>
            <v-list-item-action v-if="isAdmin && participant.id != db.auth.currentUser.uid">
              <v-btn small v-if="participant.admin" @click="removeAdmin(participant.id)">Remove admin</v-btn>
              <v-btn small v-if="!participant.admin" @click="setAdmin(participant.id)">Make admin</v-btn>
            </v-list-item-action>

            <v-chip label small v-if="participant.id == db.auth.currentUser.uid">You</v-chip>
          </v-list-item>

        <v-divider class="my-5"></v-divider>

          <p>If you would like to add people to your game, give them the following code to enter by pressing 'Join Game'</p>
          <h1 class="text-center mt-7"><code>{{board_id}}</code></h1>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    board_id: String,
    board: Object
  },
  data() {
    return {
      dialog: false,
      participants: [],
      unsubscribeFunction: null
    };
  },
  computed: {
    isAdmin() {
      return this.board.admins && this.board.admins.includes(this.db.auth.currentUser.uid)
    },
    ...mapState(["db"])
  },
  methods: {
    async showParticipants() {
      this.dialog = true
      this.participants = []
      if (this.unsubscribeFunction != null) this.unsubscribeFunction
      const refs = this.board.participants.map(id => this.db.usersCollection.doc(id).get())
      Promise.all(refs).then(docs => {
        docs.forEach(doc => {
          let part = doc.data()
          part.id = doc.id
          part.admin = this.board.admins.includes(part.id)
          this.participants.push(part);
        } )
      })

    },
    async setAdmin(userID) {
      let board = await this.db.boardsCollection.doc(this.board_id).get()
      this.db.boardsCollection.doc(this.board_id).update({
        admins: board.data().admins.concat([userID])
      })
    },
    async removeAdmin(userID) {
      let board = await this.db.boardsCollection.doc(this.board_id).get()
      this.db.boardsCollection.doc(this.board_id).update({
        admins: board.data().admins.filter(item => item != userID)
      })
    },
    async removePlayer(userID) {
      let board = await this.db.boardsCollection.doc(this.board_id).get()
      this.db.boardsCollection.doc(this.board_id).update({
        admins: board.data().admins.filter(item => item != userID),
        participants: board.data().participants.filter(item => item != userID)
      })
    }
  },
  watch: {
    board: function() {
      if (this.dialog) this.showParticipants()
    }
  }
}
</script>

<style lang="css" scoped>
</style>
