<template>
  <div id="settings">
    <v-container>
      <h3>Settings</h3>
      <p>Update your profile</p>

      <v-expand-transition>
        <p v-show="showSuccess" class="success">profile updated</p>
      </v-expand-transition>

      <v-form @submit.prevent>

        <v-text-field
        v-model.trim="name"
        :rules="[ v => !!v || 'Name is required' ]"
        label="Name"
        required
        ></v-text-field>

        <p>Your email cannot be changed at this time</p>

        <v-btn @click="updateProfile()">Update Profile</v-btn>
      </v-form>
      <v-btn class="mt-10" color="warning" @click="deleteAccount()">Delete my account</v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      name: "",
      showSuccess: false
    };
  },
  computed: {
    ...mapState(["userProfile"])
  },
  methods: {
    updateProfile() {
      this.$store.dispatch("updateProfile", {
        name: this.name !== "" ? this.name : this.userProfile.name
      })
      this.showSuccess = true
      setTimeout(() => {
        this.showSuccess = false
      }, 2000)
    },
    deleteAccount() {
      this.$store.dispatch("deleteAccount")
    }
  },
  created() {
    this.name = this.userProfile.name
  }
};
</script>
