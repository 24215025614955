<template>
  <div id="board" class="board-container">
    <v-toolbar dense color="grey darken-3" class="board-header">
      <v-toolbar-title>
        <InlineTextEditor :text="board.name" @update="saveBoardName"><span slot="prefix">T:</span></InlineTextEditor>
      </v-toolbar-title>
      <ParticipantsComponent class="ml-10" :board="board" :board_id="board_id" />

        <v-menu offset-y v-if="isAdmin">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-4" v-bind="attrs" v-on="on">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="archiveBoard()">
              <v-list-item-title>Archive this game</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      <v-spacer />
      <InlineNumberEditor :value="board.experience" @update="saveXP"><span slot="prefix" class="mr-2">Experience:</span></InlineNumberEditor>
    </v-toolbar>
    <div class="board-content">
      <draggable group="containers" handle=".handle" :list="containers" @change="dragUpdate" class="d-inline p-0 m-0" >
        <ContainerComponent v-for="container in filteredContainers" :key="container.id" :container="container" :boardAdmin="isAdmin" @view-card="viewCard" />
      </draggable>
      <v-card shaped style="width:300px;display: inline-block; vertical-align: top;" class="ml-1">
        <v-card-text>
          <v-btn block class="mr-2" @click="createContainer()">New container</v-btn>
        </v-card-text>
      </v-card>
    </div>
    <CardDetailsComponent ref="cardDetailsComponent" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContainerComponent from "@/components/ContainerComponent"
import CardDetailsComponent from "@/components/CardDetailsComponent"
import InlineNumberEditor from "@/components/InlineNumberEditor"
import ParticipantsComponent from "@/components/ParticipantsComponent"
import InlineTextEditor from "@/components/InlineTextEditor"
import draggable from "vuedraggable";
export default {
  components: {
    ContainerComponent,
    CardDetailsComponent,
    ParticipantsComponent,
    draggable,
    InlineNumberEditor,
    InlineTextEditor
  },
  data() {
    return {
      board_id: null,
      board: {},
      unsubscribeBoard: null,
      unsubscribeContainers: null,
      containers: [],
      testval: 0,
      toggle_board: 0
    };
  },
  computed: {
    isAdmin() {
      return this.board.admins && this.board.admins.includes(this.db.auth.currentUser.uid)
    },
    filteredContainers() {
      if (this.isAdmin) return this.containers
      return this.containers.filter(c => !c.hidden)
    },
    ...mapState(["db"])
  },
  methods: {
    createContainer() {
      let max_pos = Math.max.apply(Math, this.containers.map(function(c) { return c.position ? c.position : 0 }));
      this.db.containersCollection.add({
        name: "Unnamed Container",
        board_id: this.board_id,
        position: ((max_pos > 0) ? max_pos : 0) + 4096
      })
    },
    setBoard(id) {
      this.board_id = id
      this.$store.commit("clearUndo")
      if (this.unsubscribeBoard != null) this.unsubscribeBoard
      if (this.unsubscribeContainers != null) this.unsubscribeContainers

      this.unsubscribeBoard = this.db.boardsCollection.doc(this.board_id).onSnapshot(snapshot => {
        if (snapshot.exists) this.board = snapshot.data()
        else this.board = {}
      })

      this.unsubscribeContainers = this.db.containersCollection.where("board_id", "==", this.board_id).orderBy("position").onSnapshot(snapshot => {
        let containersArray = []
        snapshot.forEach(doc => {
          let container = doc.data()
          container.id = doc.id
          containersArray.push(container)
        })
        this.containers = containersArray
      })

    },
    dragUpdate(evt) {
      let changes = {}
      let event = null
      event = evt.moved.element

      if (event != null) {
        let idx = this.containers.findIndex(e => e.id === event.id)

        if (this.containers.length == 1) { changes.position = 4086 }
        else if (idx == 0) { changes.position = this.containers[1].position/2 }
        else if (idx == (this.containers.length-1) ) { changes.position = this.containers[this.containers.length-2].position+4086 }
        else { changes.position = (this.containers[idx-1].position + this.containers[idx+1].position)/2 }

        this.$store.commit("addUndo", {
          action: "update",
          entity: `containers/${event.id}`,
          values: { position: event.position }
        });
        this.db.containersCollection.doc(event.id).update(changes);
      }
    },
    saveXP(value) {
      this.$store.commit("addUndo", {
        action: "update",
        entity: `boards/${this.board_id}`,
        values: {experience: this.board.experience}
      });
      this.db.boardsCollection.doc(this.board_id).update({experience: value})

    },
    saveNote(text) {
      this.db.boardsCollection.doc(this.board_id).update({note: text})
    },
    saveBoardName(text) {
      this.$store.commit("addUndo", {
        action: "update",
        entity: `boards/${this.board_id}`,
        values: {name: this.board.name}
      });
      this.db.boardsCollection.doc(this.board_id).update({name: text})
    },
    archiveBoard() {
      if (confirm("Are you sure you want to archive this game and all its contents?")) {
        this.db.boardsCollection.doc(this.board_id).update({archived: true})
        this.$router.push("/").catch(()=>{});
      }
    },
    viewCard(card) {
      this.$refs.cardDetailsComponent.setCard(card)
    }
  },
  watch: {
    $route(to) {
      this.setBoard(to.params.id)
    }
  },
  mounted() {
    this.setBoard(this.$route.params.id)
  },
  beforeDestroy() {
    if (this.unsubscribeBoard != null) this.unsubscribeBoard
    if (this.unsubscribeContainers != null) this.unsubscribeContainers
  }
};
</script>

<style lang="scss" scoped>
.board-container {
  overflow-x: hidden;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  position: relative;
  width:100%;
  height:100%;
}
.board-header {
  flex: 0 0 auto;
  position: relative;
}
.board-content {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 4px 4px;
  min-height: 0;
  white-space: nowrap;
  overflow-x: auto;
  width:100%;
}
.fill-width {
  width: 100%;
}
</style>
