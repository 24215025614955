<template lang="html">
    <v-card shaped @click="clicked(card)" :class="card.is_magical && 'magical'" class="grey darken-3">
      <v-card-text class="d-flex">
        <span class="mr-auto">{{card.name || "Unnamed Item"}}</span>
        <v-icon color="white" dense v-if="card.is_attunement && card.is_attuned">mdi-star-three-points</v-icon>
        <v-icon color="grey" dense v-if="card.is_attunement && !card.is_attuned">mdi-star-three-points-outline</v-icon>
      </v-card-text>
      <v-chip small v-if="computedMultiples != 1" class="multiple-chip">x{{computedMultiples}}</v-chip>
    </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    card: Object
  },
  computed: {
    ...mapState(["db"]),
    computedMultiples() {
      return this.card.multiples ?? '1'
    }
  },
  methods: {
    deleteCard(cardID) {
      this.db.cardsCollection.doc(cardID).delete()
    },
    clicked(card) {
      this.$emit('view-card', card)
    },
  }
}
</script>

<style lang="css" scoped>
.magical {
  box-shadow: 0px 0px 4px 2px #0ff !important;
}
.v-card div + div {
  margin-top: -4px;
}
.multiple-chip {
  position:absolute;
   top:-5px;
   right:-3px;
   height:20px !important;
   padding-left:7px;
   padding-right:7px;
   border: 2px solid rgba(39, 39, 39, 100) !important;
}
</style>
